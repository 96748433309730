<template>
  <div>
    <b-card>
      <b-card-text>
        <div class="row">
          <div class="col-md-12">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Filtrar clientes"
            ></b-form-input>
            <b-table
              striped
              hover
              :items="persons"
              :fields="fields"
              :filter="filter"
              :current-page="currentPage"
              :per-page="perPage"
              @filtered="onFiltered"
            >
              <template #cell(full_name)="data">
                {{
                  data.item.name +
                    " " +
                    data.item.last_name +
                    " " +
                    data.item.last_name_2
                }}
              </template>
              <template #cell(actions)="data">
                <b-button
                  size="sm"
                  class="mb-2"
                  variant="success"
                  :id="'edit' + data.item.id"
                  @click="
                    $router.push({
                      name: 'person_edit',
                      params: { id: data.item.id }
                    })
                  "
                >
                  <b-icon icon="pencil" aria-hidden="true"></b-icon>
                </b-button>
                <b-tooltip :target="'edit' + data.item.id" triggers="hover">
                  Editar persona
                </b-tooltip>
              </template>
            </b-table>
          </div>
          <div class="col-md-12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
            ></b-pagination>
          </div>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "PersonList",
  components: {},
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filter: null,
      persons: [],
      fields: [
        {
          key: "full_name",
          label: "Nombre y apellidos"
        },
        {
          key: "actions",
          label: "Acciones"
        }
      ]
    };
  },
  methods: {
    onFiltered(filteredItems) {
      console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getPersons() {
      ApiService.get("person").then(res => {
        this.persons = res.data;
        this.totalRows = this.persons.length;
      });
    }
  },
  mounted() {
    this.getPersons();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Cliente" },
      { title: "Lista de clientes" }
    ]);
  }
};
</script>
